@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap");

:root {
  --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #5c6474;
  --primary-dark: #242526;
  --primary-blue: #5285b1;
  --primary-salmon: #ff8c69;
  --primary-red: #ef4035;
  --primary-green: green;
  --primary-black: #000000;

  --primary-light-pink: #b3a2a4;
}

body {
  font-family: "Raleway", sans-serif !important;
  overflow-x: hidden;
  background: var(--primary-white);
}

/*************************** NAVBAR **********************************************/

nav {
  z-index: 2;
}

.logo {
  width: 13rem;
  border-radius: 5%;
  border: solid 2px white;
}

@media (max-width: 768px) {
  .logo {
    width: 10rem;
  }
}

/************* MENU LINKS ***************/

nav a.nav-link {
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.0125rem;
  font-weight: 750 !important;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid var(--primary-dark);
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 6 rem;
}

@media (max-width: 768px) {
  .navbar-brand {
    width: 10rem;
  }
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid var(--primary-blue);
}

/*************************** HOME **********************************************/

.homephoto {
  margin-top: 4.021rem;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
}

.header-wrapper {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Raleway", sans-serif;
  z-index: 1;
}

.main-info h1 {
  color: var(--primary-white);
  font-family: "Raleway Mono", sans-serif;
  font-weight: 500;
  font-size: 225%;
  text-shadow: 2px 2px 0 var(--primary-black);
}

.main-info .home-h1-span {
  text-transform: uppercase;
  font-size: 200%;
  text-shadow: 3px 3px 0 var(--primary-black);
}

/************* TYPED TEXTS ***************/

.typed-text {
  font-size: 2rem;
  color: var(--primary-black);
  font-weight: bold;
}

@media (max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
}

/************* HEADER MAIN OFFER BUTTON ***************/
.btn-main-offer {
  text-transform: uppercase;
  border-radius: 3%;
  padding: 0.75rem 0.875rem 0.875rem 0.875rem;
  color: var(--primary-white);
  font-weight: 400;
  margin-top: 2rem;
  background-color: var(--primary-blue);
  border: 1px solid var(--primary-black) !important;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: var(--primary-blue);
  border: 1px solid var(--primary-white) !important;
  color: var(--primary-white);
  transition: 0.2s ease-in-out;
}

/*************************** ABOUT **********************************************/

.about .container {
  margin-top: 6.252rem;
}

.about-wrapper {
  height: 120%;
}

.about h1 {
  padding: 2rem;
  text-align: center;
  text-transform: uppercase;
  color: var(--primary-blue);
  font-weight: bold;
}

.about h2 {
  color: var(--primary-blue);
}

.about h3 {
  color: var(--primary-salmon);
}

.about-info {
  border-bottom: 1px solid var(--primary-blue);
  font-weight: bold !important;
  color: var(black);
  text-align: center;
  font-size: 1.2rem;
}

/************* FOUNDERS DESC ***************/

.christian-col {
  text-align: center;
}

.christian-col img {
  width: 24rem;
  height: 25rem;
  border-radius: 50%;
}

.christian-col h2 {
  font-weight: 1000;
}

.christian-col p {
  font-weight: 800;
  color: var(black);
}

.christian-col .icon {
  margin-right: 1rem;
}

.christian-col h3 {
  font-weight: 600;
}

.kara-col {
  text-align: center;
}

.kara-col img {
  width: 24rem;
  height: 25rem;
  border-radius: 50%;
}

.kara-col h2 {
  font-weight: 1000;
}

.kara-col p {
  font-weight: 800;
  color: var(black);
}

.kara-col .icon {
  margin-right: 1rem;
}

.kara-col h3 {
  font-weight: 600;
}

/************* MEDIA ***************/

@media (max-width: 375px) {
  .christian-col img {
    width: 17rem;
    height: 18rem;
  }

  .kara-col img {
    width: 17rem;
    height: 18rem;
  }
}

@media (max-width: 768px) {
  .about .container {
    margin-top: 4.94rem;
  }

  .christian-col {
    background-color: var(--primary-light-grey);
  }

  .christian-col .icon {
    margin-bottom: 1rem;
  }

  .kara-col {
    margin-top: 1rem;
    background: var(--primary-white);
  }

  .kara-col img {
    margin-top: 1rem;
  }

  .kara-col .icon {
    margin-bottom: 1rem;
  }
}

@media (max-width: 411px) {
  .christian-col {
    padding: 1rem;
  }

  .kara-col {
    padding: 1rem;
  }
}

@media (max-width: 768px) {
  @media (min-width: 580px) {
    .kara-col {
      background: none;
    }
    .kara-col img {
      width: 15rem;
      height: 16rem;
    }
    .christian-col img {
      width: 15rem;
      height: 16rem;
    }
  }
}

/*************************** SERVICES **********************************************/

.services .container {
  margin-top: 6.252rem;
  text-align: center;
}

.services h1 {
  font-size: 3rem !important;
  padding: 2rem;
  text-align: center;
  text-transform: uppercase;
  color: var(--primary-blue);
  font-weight: bold;
}

.services .icon {
  color: var(--primary-salmon) !important;
  margin: 1rem;
}

.services .box {
  padding: 3;
  font-size: 1.25rem;
  border-bottom: 1px solid var(--primary-blue);
  height: 20rem;
}

.services .box-info {
  text-align: center;
}

.box h3 {
  text-transform: uppercase;
  color: var(--primary-blue) !important;
  font-weight: bold;
}

/************* MEDIA ***************/

@media (max-width: 767px) {
  .service-col {
    margin: 0 0 1rem 0;
  }
}

@media (max-width: 360px) {
  .service-col {
    margin: 0 0 13rem 0;
  }
}

@media (max-width: 320px) {
  .services .box {
    margin-top: 1rem;
  }
}

@media (min-width: 1024px) {
  .services .first-row {
    margin: 0 0 3rem 0;
  }
  .services .first-row .box {
    height: 25rem;
  }
  .services .second-row .box {
    height: 20rem;
  }
}

@media (max-width: 768px) {
  @media (min-width: 580px) {
    .services .first-row {
      margin: 0 0 3rem 0;
    }
    .services .first-row .box {
      height: 31rem;
    }
    .services .second-row .box {
      height: 23rem;
    }
  }
}

/*************************** CONTACTS **********************************************/
.contacts {
  padding: 15rem 3rem 3rem 3rem;
}

.contacts h1 {
  color: var(--primary-blue);
  font-weight: bold;
  pad: 1.3rem 0 2rem 0;
  text-transform: uppercase;
}
.contacts h2 {
  color: var(--primary-salmon);
  font-weight: 600 !important;
  pad: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: var(--primary-dark);
  font-size: 1.2rem;
  font-weight: bold;
  padding-top: 1rem;
}

/************* INPUTS ***************/

.contacts input,
.contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: none;
  border-bottom: 0.12rem solid var(--primary-dark);
  border-radius: 0;
  color: var(--primary-blue);
  outline: 0 !important;
  box-shadow: none !important;
  top: 0;
  margin-bottom: 1rem;
  padding-left: 0 !important;
  font-weight: bold;
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus,
.contacts textarea:focus {
  background: transparent;
  color: var(--primary-blue);
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contacts textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
  color: var(--primary-dark);
  font-weight: bold;
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 0.125rem;
  background: var(--primary-blue);
  display: inline-block;
}

input:focus + .line,
textarea:focus + .line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: var(--primary-red);
  font-weight: bold;
  text-align: left;
}

.success-message {
  font-weight: 900;
  color: var(--primary-green);
}

/************* MEDIA ***************/
@media (max-width: 768px) {
  .contacts {
    overflow-x: hidden;
  }

  .contacts .container {
    padding: 0 1rem;
  }

  .contacts p {
    padding: 0 0.4rem;
  }
}

/*************************** FOOTER **********************************************/

.footer {
  background: #111111;
  color: var(--primary-white);
  padding: 1rem 3rem 1rem 3rem;
  z-index: 3;
  position: absolute;
  top: 20;
  width: 100%;
}

.footer a {
  text-decoration: none;
  color: var(--primary-dark-grey);
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-blue);
}

.footer p {
  margin-bottom: 0;
}
